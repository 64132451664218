//@ts-nocheck
import _ from "lodash";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import classNames from "classnames";
import * as d3 from "d3";
import {
  Button,
  Spin,
  Row,
  Col,
  Card,
  Select,
  Skeleton,
  message,
  Empty,
  Flex,
  Tabs,
  Modal,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { InfoCircleOutlined } from "@ant-design/icons";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import PAGES from "routes/constants";
import { MAP_COLOR_DPE } from "constants/index";
import { ReactComponent as IconPen } from "assets/images/pen.svg";
import { ReactComponent as IconSetting } from "assets/images/setting.svg";
import { ReactComponent as IconLocation } from "assets/images/location.svg";
import { ReactComponent as IconHouse } from "assets/images/house.svg";
import { ReactComponent as IconRuler } from "assets/images/ruler.svg";
import { ReactComponent as IconDoor } from "assets/images/door.svg";
import { ReactComponent as IconCalendar } from "assets/images/calendar-2.svg";
import { ReactComponent as IconStair } from "assets/images/stair.svg";
import { ReactComponent as IconEnergy } from "assets/images/energy.svg";
import { ReactComponent as IconTemperature } from "assets/images/temperature.svg";
import { ReactComponent as IconWater } from "assets/images/water.svg";
import { ReactComponent as IconWall } from "assets/images/wall.svg";
import { ReactComponent as IconWind } from "assets/images/wind.svg";
import { ReactComponent as IconIce } from "assets/images/ice.svg";
import "styles/home.scss";

const MAX_VALUE_FIABILITE = 10;

function HomeResult() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCGcf514ApTEBKV3u0HyCiLKJmVi_Fimw0",
  });

  const [messageApi, contextHolder] = message.useMessage();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmallDesktop = useMediaQuery({ query: "(max-width: 1164px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const barChartRef = useRef<any>();
  const dotFiabiliteProgressRef = useRef<any>();

  const navigate = useNavigate();
  const { id } = useParams();

  const [dataChart, setDataChart] = useState<any[]>([]);
  const [dataHomeUploaded, setDataHomeUploaded] = useState(null);
  const [Resultat, setResultat] = useState(null);
  const [tab, setTab] = useState("1");
  const [tabMap, setTabMap] = useState("1");
  const [isShowModalMapInfo, setIsShowModalMapInfo] = useState(false);
  const [dataModalFiabilite, setDataModalFiabilite] = useState(null);
  const [isShowModalFiabilite, setIsShowModalFiabilite] = useState(false);
  const [, setMap] = useState(null);

  const { data: dataFiabilite, isFetching: isFetchingFiabilite } = useQuery({
    queryKey: [QUERY_KEYS.GET_DATA_FIABILITE],
    queryFn: API.mock.getDataFiabilite,
  });

  const {
    mutateAsync: mutateUploaded,
    isPending: isPendingUploaded,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: (params: any) => API.mock.getHomeUploadData(params),
  });

  const { mutateAsync: mutateSVG, isPending: isPendingSvg } = useMutation({
    mutationFn: (params: any) => API.mock.getResultat(params),
  });

  // const { data: Resultat } = useQuery({
  //   queryKey: [QUERY_KEYS.GET_HOME_DATA_RESULTAT],
  //   queryFn: API.mock.getResultat,
  // });

  const { data: geoMap } = useQuery({
    queryKey: [QUERY_KEYS.GET_GEO_MAP],
    queryFn: API.mock.getGEOMap,
  });

  console.log(geoMap);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const result = await mutateUploaded({
        id,
      });

      setDataHomeUploaded(result);
    } catch (e: any) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    }

    try {
      const resultSVG = await mutateSVG({ id });
      setResultat(resultSVG);
    } catch (e: any) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if (dataHomeUploaded) {
      const cloneData = _.cloneDeep(
        _.get(dataHomeUploaded, "distribution.values", {})
      ) as any;

      const convertedData = Object.keys(cloneData).map((key, index) => {
        return {
          label: key,
          value: cloneData[key] * 100,
          color: MAP_COLOR_DPE[key],
          highlight: key === (dataHomeUploaded as any)?.results?.dpe_letter,
        };
      });
      setDataChart(convertedData);
    }
  }, [dataHomeUploaded]);

  useEffect(() => {
    if (!_.isEmpty(dataChart) && !isPendingUploaded) {
      drawNewChart();
    }

    if (!_.isEmpty(dataFiabilite) && !isFetchingFiabilite) {
      drawFiabilite();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataChart,
    dataFiabilite,
    isFetchingFiabilite,
    isPendingUploaded,
    isTabletOrMobile,
    isSmallDesktop,
    isMobile,
  ]);

  const handleToEditPage = () => {
    if (dataHomeUploaded) {
      navigate({
        pathname: PAGES.edit.replace(":id", (dataHomeUploaded as any).id),
        // search: createSearchParams({
        //   mode: "edit",
        // }).toString(),
      });
    }
  };

  const handleSetDataModalFiabilite = (data) => {
    setDataModalFiabilite(data);
    setIsShowModalFiabilite(true);
  };

  const drawNewChart = () => {
    const chart = d3.select(barChartRef.current);

    chart
      .selectAll(".bar")
      .data(dataChart)
      .enter()
      .append("div")
      .attr("class", "bar")
      .style("height", (d) => `${d.value * 3}px`)
      .style("background-color", (d) => d.color)
      .style("border", (d) => (d.highlight ? "2px solid black" : "none"))
      .each(function (d) {
        d3.select(this)
          .append("div")
          .attr("class", "bar-label")
          .text(`${d.value}%`);

        d3.select(this).append("div").attr("class", "indicator").html(d.label);

        if (d.highlight) {
          d3.select(this)
            .append("div")
            .attr("class", "indicator-triangle")
            .html(
              '<div class="triangle"><div class="inner-triangle"></div></div>'
            );
        }
      });
  };

  const drawFiabilite = () => {
    // Calculate the position of the pointer
    const gaugeElement = document.getElementById("gauge");
    if (!gaugeElement) {
      return;
    }

    // Set the value you want to point to (0 to 10 scale)
    const value = dataFiabilite.reliability?.level || 0; // Example value
    const maxValue = MAX_VALUE_FIABILITE;

    const gaugeWidth = gaugeElement.offsetWidth;
    const pointerPosition = (value / maxValue) * gaugeWidth;

    // Create a canvas to extract the color from the gradient
    const canvas = document.createElement("canvas");
    canvas.width = gaugeWidth;
    canvas.height = 1;
    const context = canvas.getContext("2d");

    // Apply the gradient to the canvas
    const gradient = context.createLinearGradient(0, 0, gaugeWidth, 0);
    gradient.addColorStop(0, "red");
    gradient.addColorStop(0.5, "yellow");
    gradient.addColorStop(1, "green");
    context.fillStyle = gradient;
    context.fillRect(0, 0, gaugeWidth, 1);

    // Get the color at the pointer position
    const imageData = context.getImageData(pointerPosition, 0, 1, 1).data;
    const pointerColor = `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`;

    // Move the pointer and set its color
    const pointer = document.getElementById("pointer");
    pointer.style.left = `${pointerPosition}px`;
    pointer.style.backgroundColor = pointerColor;

    dotFiabiliteProgressRef.current.style.backgroundColor = pointerColor;
  };

  // const pdfFileName = useMemo(() => {
  //   if (dataHomeUploaded) {
  //     const arrString = (dataHomeUploaded as any).pdf?.split("/");
  //     return arrString[arrString.length - 1];
  //   }
  //   return "";
  // }, [dataHomeUploaded]);

  const centerPoint = useMemo(() => {
    const firstValue = _.get(geoMap, "features.0.geometry.coordinates");
    if (!_.isEmpty(firstValue)) {
      return { lat: firstValue[0], lng: firstValue[1] };
    }
    return {};
  }, [geoMap]);

  const polygonPoint = useMemo(() => {
    const arrayValue = _.get(geoMap, "features.2.geometry.coordinates");
    if (!_.isEmpty(arrayValue)) {
      return _.get(arrayValue, "0", []).map((item: any) => {
        return {
          lat: item[0],
          lng: item[1],
        };
      });
    }
    return {};
  }, [geoMap]);

  const onLoad = useCallback(
    function callback(map: any) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      // if (!_.isNil(centerPoint.lat) && !_.isNil(centerPoint.lng)) {
      //   const bounds = new window.google.maps.LatLngBounds(centerPoint as any);
      //   map.fitBounds(bounds);

      //   setMap(map);
      // }

      // const triangleCoords = [
      //   { lat: 25.774, lng: -80.19 },
      //   { lat: 18.466, lng: -66.118 },
      //   { lat: 32.321, lng: -64.757 },
      //   { lat: 25.774, lng: -80.19 },
      // ];

      const bermudaTriangle = new google.maps.Polygon({
        paths: polygonPoint,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      bermudaTriangle.setMap(map);

      // map.data.add({
      //   geometry: new google.maps.Data.Polygon([polygonPoint]),
      // });
    },
    [polygonPoint]
  );

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const tabItems: TabsProps["items"] = useMemo(() => {
    const renderTabDuBien = () => {
      const TAB_DU_BIEN1 = [
        {
          icon: IconRuler,
          label: "",
          value: _.get(dataHomeUploaded, "project_details.surface.1", ""),
        },
        {
          icon: IconDoor,
          label: "",
          value: _.get(dataHomeUploaded, "project_details.housing_setup.1", ""),
        },
        {
          icon: IconCalendar,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.construction_year.0",
            ""
          )} `,
          value: _.get(
            dataHomeUploaded,
            "project_details.construction_year.1",
            ""
          ),
        },
        {
          icon: IconStair,
          label: `${_.get(dataHomeUploaded, "project_details.floor.0", "")} : `,
          value: _.get(dataHomeUploaded, "project_details.floor.1", ""),
        },
        {
          icon: IconEnergy,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.energy.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.energy.1", ""),
        },
      ];

      const TAB_DU_BIEN2 = [
        {
          icon: IconTemperature,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.heating_system.0",
            ""
          )} : `,
          value: _.get(
            dataHomeUploaded,
            "project_details.heating_system.1",
            ""
          ),
        },
        {
          icon: IconWater,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.hot_water.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.hot_water.1", ""),
        },
        {
          icon: IconWall,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.materials.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.materials.1", ""),
        },
        {
          icon: IconWind,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.airflow.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.airflow.1", ""),
        },
        {
          icon: IconIce,
          label: `${_.get(
            dataHomeUploaded,
            "project_details.aircon.0",
            ""
          )} : `,
          value: _.get(dataHomeUploaded, "project_details.aircon.1", ""),
        },
      ];

      return (
        <div className="info-du-bien">
          <ul>
            {TAB_DU_BIEN1.map((item, index) => {
              const Icon = item.icon;
              return (
                <li key={index}>
                  <Flex align="center">
                    <Icon className="mr-1" />
                    {item.label && (
                      <span className="text-medium mr-1">{item.label}</span>
                    )}
                    {item.value && (
                      <p className="text-medium-bold capitalize text-truncate">
                        {item.value}
                      </p>
                    )}
                  </Flex>
                </li>
              );
            })}
          </ul>

          <ul>
            {TAB_DU_BIEN2.map((item, index) => {
              const Icon = item.icon;
              return (
                <li key={index}>
                  <Flex align="flex-start">
                    <div className="flex items-center flex-shrink-0">
                      <Icon className="mr-1 flex-shrink-0" />
                      {item.label && (
                        <p className="text-medium mr-1">{item.label} </p>
                      )}
                    </div>
                    {item.value && (
                      <p className="text-medium-bold capitalize text-truncate max-w-[90%]">
                        {item.value}
                      </p>
                    )}
                  </Flex>
                </li>
              );
            })}
          </ul>
        </div>
      );
    };

    return [
      { key: "1", label: "Infos du bien", children: renderTabDuBien() },
      { key: "2", label: "Infos du DPE", children: "Content of Tab Pane 2" },
    ];
  }, [dataHomeUploaded]);

  const renderMap = () => {
    return (
      <div className="map-wrapper">
        <MapContainer
          center={[
            _.get(dataHomeUploaded, "geo_data.lat"),
            _.get(dataHomeUploaded, "geo_data.lng"),
          ]}
          zoom={15}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[
              _.get(dataHomeUploaded, "geo_data.lat"),
              _.get(dataHomeUploaded, "geo_data.lng"),
            ]}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          {/* <Circle
        center={[10.7352, 106.6299]}
        color="red"
        radius={500}
        fillColor="#f03"
        fillOpacity={0.5}
      />
      <Polygon
        positions={[
          [10.73617, 106.64103],
          [10.73447, 106.64395],
          [10.73647, 106.64495],
        ]}
      /> */}
        </MapContainer>
      </div>
    );
  };

  const renderGoogleMap = () => {
    return (
      <div className="energy-home__maps">
        <div className="map-wrapper">
          {isLoaded && centerPoint && (
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
              center={{ lat: -33.872, lng: 151.252 }}
              zoom={5}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {/* Child components, such as markers, info windows, etc. */}
            </GoogleMap>
          )}
        </div>
      </div>
    );
  };

  const tabMapItems: TabsProps["items"] = [
    { key: "1", label: "Plan", children: renderMap() },
    // { key: "2", label: "Satellite", children: renderMap() },
    { key: "2", label: "Cadastre", children: renderGoogleMap() },
  ];

  const renderResultUploaded = () => {
    if (isPendingUploaded || isFetchingFiabilite) {
      return (
        <div className="loading-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      );
    }

    if (isError && _.isEmpty(dataHomeUploaded)) {
      return (
        <Card className="mb-3 min-h-[400px] flex items-center justify-center">
          <Empty />
        </Card>
      );
    }

    if (isSuccess && !_.isEmpty(dataHomeUploaded)) {
      return (
        <div className="energy-home__result">
          <Card
            title={<h4>{_.get(dataHomeUploaded, "project_name")}</h4>}
            className="energy-home__project-card mb-3"
          >
            <div className="energy-home__project">
              <Flex align="center" className="mb-2">
                <IconLocation />
                <span className="text-medium-bold ml-1">
                  {_.get(dataHomeUploaded, "project_details.address.1", "")}
                </span>
              </Flex>
              <Flex align="center">
                <IconHouse />
                <p className="text-medium-medium ml-1">
                  {_.get(
                    dataHomeUploaded,
                    "project_details.housing_type.0",
                    ""
                  )}
                  <span className="text-medium-bold">
                    {" "}
                    :{" "}
                    {_.get(
                      dataHomeUploaded,
                      "project_details.housing_type.1",
                      ""
                    )}
                  </span>
                </p>
              </Flex>

              <div className="energy-home__project-info">
                <Tabs
                  centered
                  activeKey={tab}
                  items={tabItems}
                  onChange={setTab}
                />

                <div className="energy-home__project-info__indice">
                  <Flex align="flex-start" justify="space-between">
                    <p className="text-large mb-1">Indice de fiabilité</p>
                    <div className="flex items-center">
                      <span
                        className="dot"
                        ref={dotFiabiliteProgressRef}
                      ></span>
                      <span className="fiabilite-number">
                        {dataFiabilite.reliability?.level}
                      </span>
                    </div>
                  </Flex>

                  <div className="fiabilite-container">
                    <div className="gauge" id="gauge">
                      <div id="pointer" className="pointer"></div>
                    </div>
                    <div className="labels">
                      <div>
                        Pas du tout
                        <br /> fiable
                      </div>
                      <div>Ultra fiable</div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-2">
                      <span className="dot-large dot-qualite"></span>
                      <span className="text-regular-bold">
                        {_.get(dataFiabilite, "reliability.quality.label", "")}
                      </span>
                      <InfoCircleOutlined
                        onClick={() =>
                          handleSetDataModalFiabilite(
                            _.get(dataFiabilite, "reliability.quality", null)
                          )
                        }
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="dot-large dot-coherence"></span>
                      <span className="text-regular-bold">
                        {_.get(
                          dataFiabilite,
                          "reliability.consistency.label",
                          ""
                        )}
                      </span>
                      <InfoCircleOutlined
                        onClick={() =>
                          handleSetDataModalFiabilite(
                            _.get(
                              dataFiabilite,
                              "reliability.consistency",
                              null
                            )
                          )
                        }
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="dot-large dot-issue">
                        {_.get(dataFiabilite, "reliability.issues.number", "")}
                      </span>
                      <span className="text-regular-bold">
                        {_.get(dataFiabilite, "reliability.issues.label", "")}
                      </span>
                      <InfoCircleOutlined
                        onClick={() =>
                          handleSetDataModalFiabilite(
                            _.get(dataFiabilite, "reliability.issues", null)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <Row
            gutter={[16, 16]}
            className="mb-3"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col span={24} lg={12} style={{ paddingLeft: 0 }}>
              <Card>
                <div className="energy-home__resultats">
                  <h3 className="text-xl mb-3">
                    <b>Résultats</b>
                  </h3>
                  <div className="text-center">
                    {isPendingSvg && !Resultat ? (
                      <div className="svg-image">
                        <Skeleton.Button active />
                      </div>
                    ) : (
                      <img
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          Resultat || ""
                        )}`}
                        alt="result"
                      />
                    )}

                    <Button
                      type="primary"
                      onClick={handleToEditPage}
                      className="mt-7"
                    >
                      ouvrir DPE
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={24} lg={12} style={{ paddingRight: 0 }}>
              <Card className="h-full">
                <div className="energy-home__maps">
                  <div className="flex justify-between">
                    <h3 className="text-xl">
                      <b>Maps</b>
                    </h3>
                    <Button
                      type="link"
                      className="flex items-center justify-center p-0 w-9 h-9"
                      onClick={() => setIsShowModalMapInfo(true)}
                    >
                      <InfoCircleOutlined
                        style={{ color: "#000", fontSize: 22 }}
                      />
                    </Button>
                  </div>

                  <Flex align="center" className="mt-2 mb-4">
                    <IconLocation />
                    <span className="text-regular-bold sm:text-medium-bold ml-1">
                      {_.get(dataHomeUploaded, "project_details.address.1", "")}
                    </span>
                  </Flex>

                  <div className="energy-home__map-info">
                    <Tabs
                      centered
                      activeKey={tabMap}
                      items={tabMapItems}
                      onChange={setTabMap}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <Card className="mb-3">
            <div className="energy-home__notifications">
              <h3 className="text-xl mb-4">
                <b>Notifications</b>
              </h3>
              <ul className="notification flex flex-col items-center gap-3 list-disc pl-5">
                {!!_.get(dataHomeUploaded, "notifications", []).length &&
                  _.get(dataHomeUploaded, "notifications", []).map(
                    (item: any, index: number) => {
                      return (
                        <li
                          className={classNames([
                            "notification-item",
                            item.type,
                          ])}
                          key={index}
                        >
                          <span>{item.message}</span>
                        </li>
                      );
                    }
                  )}
              </ul>

              <div className="text-center mt-6">
                <Button disabled className="min-w-[108px]">
                  Plus
                </Button>
              </div>
            </div>
          </Card>

          <Card className="mb-3">
            <div className="energy-home__distribution">
              <div className="flex justify-between">
                <div>
                  <h3 className="text-xl">
                    <b>{_.get(dataHomeUploaded, "distribution.title", "")}</b>
                  </h3>
                  <p>{_.get(dataHomeUploaded, "distribution.subtitle", "")}</p>
                </div>

                <div>
                  <p className="mb-0.5">Scope</p>
                  <Select
                    className="w-[120px]"
                    defaultValue={_.get(dataHomeUploaded, "distribution.scope")}
                    options={[
                      {
                        label: _.get(dataHomeUploaded, "distribution.scope"),
                        value: _.get(dataHomeUploaded, "distribution.scope"),
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="bar-chart" ref={barChartRef}></div>
            </div>
          </Card>

          <Card>
            <div className="energy-home__corrections">
              <h3 className="text-xl mb-4">
                <b>Corrections suggérées</b>
              </h3>
              <div className="flex flex-col gap-3">
                {!!_.get(dataHomeUploaded, "suggested", []).length &&
                  _.get(dataHomeUploaded, "suggested", []).map(
                    (item: any, index: number) => {
                      const Icon = MAP_CORRECTION_ICON[item.type];
                      return (
                        <div className="corrections-item" key={index}>
                          <Row gutter={[4, 4]}>
                            <Col
                              span={3}
                              lg={1}
                              className="flex items-center justify-center"
                            >
                              <Icon />
                            </Col>
                            <Col
                              span={21}
                              lg={23}
                              className="flex items-center"
                            >
                              <p>{item.message}</p>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  )}
              </div>

              <div className="text-center mt-4">
                <Button disabled className="min-w-[108px]">
                  Plus
                </Button>
              </div>
            </div>
          </Card>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="energy-home energy-home--result">
      {renderResultUploaded()}
      {contextHolder}
      <Modal
        open={isShowModalMapInfo}
        centered
        onCancel={() => setIsShowModalMapInfo(false)}
        onOk={() => setIsShowModalMapInfo(false)}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div className="p-4">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
            unde vitae sit placeat mollitia molestias animi quasi amet eaque
            quo, culpa delectus quae quisquam iusto obcaecati dicta voluptate.
            Deleniti, aspernatur?
          </p>
        </div>
      </Modal>

      <Modal
        open={isShowModalFiabilite}
        centered
        title={_.get(dataModalFiabilite, "details.title", "")}
        onCancel={() => setIsShowModalFiabilite(false)}
        onOk={() => setIsShowModalFiabilite(false)}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div className="p-4">
          <ul style={{ listStyleType: "disc" }} className="flex flex-col gap-2">
            {_.get(dataModalFiabilite, "details.body", []).map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
}

const MAP_CORRECTION_ICON: any = {
  completion: IconPen,
  construction: IconSetting,
};

export default HomeResult;
