import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormProps,
  UploadProps,
  message,
  Upload,
  Spin,
  Input,
  Tabs,
  TabsProps,
  Typography,
  AutoComplete,
  Modal,
  Empty,
  Row,
  Col,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";

import {
  InboxOutlined,
  LoadingOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import { getHomeResultURL } from "routes/constants";
import { getVotreItems } from "constants/constant-jsx";

import { setProject } from "services/storages/projectStorage";

import Cover from "assets/images/cover.png";
import Cover2 from "assets/images/cover-2.jpg";
import Cover3 from "assets/images/cover-3.jpg";
import Cover4 from "assets/images/cover-4.jpg";
import Cover5 from "assets/images/cover-5.jpg";
import Cover6 from "assets/images/cover-6.jpg";
import Cover7 from "assets/images/cover-7.jpg";

import { FeatureType } from "services/api/type/mock.type";
import { MAP_COLOR_DPE } from "constants/index";
import "styles/home.scss";

const coverBg = [Cover, Cover2, Cover3, Cover4, Cover5, Cover6, Cover7];

interface Props {
  landingLocale?: any;
  personaeLocale?: any;
  bannerTwo?: any;
  isPersonaePage?: boolean;
}

function Home({
  landingLocale,
  personaeLocale,
  isPersonaePage,
  bannerTwo,
}: Props) {
  const [formUpload] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [fileName, setFileName] = useState("");
  const [ademe, setAdeme] = useState("");
  const [bgCover, setBgCover] = useState("");
  const [tab, setTab] = useState("1");
  const [search, setSearch] = useState<string>("");
  const [searchAPI, setSearchAPI] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [resultAutocomplete, setResultAutocomplete] = useState<any[]>([]);
  const [isShowModalResultAutocomplete, setIsShowModalResultAutocomplete] =
    useState(false);

  const { data: dataBeforeUpload, isFetching: isFetchingDataUpload } = useQuery(
    {
      queryKey: [QUERY_KEYS.GET_DATA_UPLOAD, fileName],
      queryFn: () => API.mock.getDataUpload(fileName),
      enabled: !!fileName,
    }
  );

  const { data: dataAutoComplete } = useQuery({
    queryKey: [QUERY_KEYS.AUTOCOMPLETE_SEARCH, searchAPI],
    queryFn: () => API.mock.autoCompleteSearch(searchAPI),
    enabled: !!searchAPI,
  });

  const { mutateAsync: mutateUploadFile, isPending } = useMutation({
    mutationFn: ({ path, data }: { path: string; data: any }) =>
      API.mock.uploadFile(path, data),
  });
  const { mutateAsync: mutateResultat, isPending: isPendingResultat } =
    useMutation({
      mutationFn: (params: string) => API.mock.submitResultat(params),
    });

  const {
    mutateAsync: mutateGetResultAutocomplete,
    isPending: isPendingGetResultAutocomplete,
  } = useMutation({
    mutationFn: (address: string) => API.mock.getResultAutocomplete(address),
  });

  useEffect(() => {
    const randomBg = _.sample(coverBg);
    setBgCover(randomBg);
  }, []);

  useEffect(() => {
    const features: FeatureType[] = _.get(dataAutoComplete, "features", []);
    const newOptions = features.map((item) => ({
      value: _.get(item, "properties.label", ""),
    }));
    setOptions(newOptions);
  }, [dataAutoComplete]);

  const handleUpload: FormProps["onFinish"] = async (values: any) => {
    // console.log("onFinish:", values.file[0]);
    try {
      const formData = new FormData();
      formData.append("key", dataBeforeUpload.fields.key);
      formData.append("AWSAccessKeyId", dataBeforeUpload.fields.AWSAccessKeyId);
      formData.append(
        "x-amz-security-token",
        dataBeforeUpload.fields["x-amz-security-token"]
      );
      formData.append("policy", dataBeforeUpload.fields.policy);
      formData.append("signature", dataBeforeUpload.fields.signature);
      formData.append("file", values.file[0].originFileObj);

      await mutateUploadFile({
        path: dataBeforeUpload.url,
        data: formData,
      });

      formUpload.resetFields();
      setFileName("");
      messageApi.open({
        type: "success",
        content: "Chargment OK",
      });
      navigate(getHomeResultURL(dataBeforeUpload?.metadata?.id));
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_MOCK_DATA] });
    } catch (e: any) {
      console.log(e.response);
      messageApi.open({
        type: "error",
        content: "Chargment Échec",
      });
    }
  };

  const handleSubmitResultat = async () => {
    const result = await mutateResultat(ademe);
    setProject(result.id);
    navigate(getHomeResultURL(result.id));
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const handleSearch = (text: string) => {
    setSearch(text);
    handleThrottleSearch(text);
  };

  const handleSearchAPI = (text: string) => {
    setSearchAPI(text);
  };

  const onSelect = async (data: string) => {
    console.log("onSelect", data);
    setSearch(data);
    const result = await mutateGetResultAutocomplete(data);
    setResultAutocomplete(result.resp_addresses);
    setIsShowModalResultAutocomplete(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleThrottleSearch = useCallback(
    _.throttle(handleSearchAPI, 1000),
    []
  );

  const handleNavigateToHomeResult = (item: any) => {
    navigate(getHomeResultURL(_.get(item, "ademe_id.1")));
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const props: UploadProps = {
    accept: "*",
    name: "file",
    multiple: false,
    onChange({ file }) {
      setFileName(file.name);
    },
    beforeUpload: () => {
      return false;
    },
    showUploadList: true,
    maxCount: 1,
    disabled: isFetchingDataUpload,
  };

  const tabMapItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Upload DPE",
      children: (
        <Form
          form={formUpload}
          name="upload-form"
          autoComplete="off"
          layout="vertical"
          initialValues={{
            file: null,
            votre: "",
          }}
          onFinish={handleUpload}
        >
          <div
            className="flex items-start gap-8 inner-form"
            data-testid="upload-drag"
          >
            <Form.Item
              name="file"
              // label="Upload your DPE"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="w-full m-0"
            >
              <Upload.Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  {isFetchingDataUpload ? (
                    <Spin indicator={<LoadingOutlined />} size="large" />
                  ) : (
                    <InboxOutlined />
                  )}
                </p>
                <p className="ant-upload-text">
                  Déposez votre DPE (fichier pdf) ici pour l'uploader
                </p>
              </Upload.Dragger>
            </Form.Item>
          </div>
          {dataBeforeUpload && (
            <Button
              htmlType="submit"
              type="primary"
              loading={isPending}
              className="mt-4"
              size="large"
              disabled={!fileName || _.isEmpty(dataBeforeUpload)}
            >
              Upload
            </Button>
          )}
        </Form>
      ),
    },
    {
      key: "2",
      label: "par Numéro ADEME",
      children: (
        <div className="ademe-box">
          <div className="energy-home__search">
            <label htmlFor="search-banner" className="w-full">
              <Typography.Title level={4} style={{ color: "#fff" }}>
                Votre numéro ADEME
              </Typography.Title>
              <Input
                id="search-banner"
                value={ademe}
                onChange={(e) => setAdeme(e.target.value)}
              />
            </label>
            <Button
              type="primary"
              loading={isPendingResultat}
              onClick={handleSubmitResultat}
            >
              résultats
            </Button>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "par Adresse",
      children: (
        <div className="ademe-box">
          <Typography.Title level={4} style={{ color: "#fff" }}>
            Adresse
          </Typography.Title>
          <div className="relative">
            {/* <Input
              allowClear
              value={search}
              className={classNames("w-full p-[7px]", {
                "has-value": search,
              })}
              onChange={handleSearch}
              placeholder="Search"
            /> */}

            <AutoComplete
              allowClear
              options={options}
              value={search}
              className={classNames("w-full mr-4")}
              onSelect={onSelect}
              onSearch={handleSearch}
              placeholder="Search"
            />

            {isPendingGetResultAutocomplete && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: "24px" }} />}
                className="absolute top-1/2 right-4 -translate-y-1/2"
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  const votreItems = getVotreItems(landingLocale);

  return (
    <>
      <div
        className="energy-home__banner"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("${
            isPersonaePage ? personaeLocale?.bg_image : bgCover
          }")`,
        }}
      >
        <h1 className="energy-home__title text-center">
          {isPersonaePage ? personaeLocale?.title : landingLocale?.all?.title}
        </h1>
        <h3 className="energy-home__desc text-center">
          {isPersonaePage
            ? personaeLocale?.description
            : landingLocale?.all?.description}
        </h3>

        <Tabs
          centered
          activeKey={tab}
          items={tabMapItems}
          onChange={(tabChange) => {
            setTab(tabChange);
            setSearch("");
            setSearchAPI("");
            setOptions([]);
          }}
        />
      </div>

      <div className="energy-home">
        {/* data-testid={`votre-${item.value}`} */}
        <div className="energy-home__votre">
          {votreItems.map((item, index) => (
            <Link
              to={item.url}
              key={index}
              className="energy-home__votre-item-link"
            >
              <div
                className="energy-home__votre-item"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("${item.thumbnail}")`,
                }}
              >
                <p className="energy-home__votre-item__title">{item.label}</p>
                <p className="energy-home__votre-item__desc">{item.desc}</p>
                {/* <img src={item.thumbnail} alt="votre-img"/> */}
              </div>
            </Link>
          ))}
        </div>

        {bannerTwo && (
          <div className="energy-home__banner-2">
            <Row gutter={[16, 24]}>
              <Col xs={24} lg={12}>
                <img src={bannerTwo?.bg_image} alt="banner" />
              </Col>
              <Col xs={24} lg={12}>
                <div className="max-w-[80%]">
                  <h2 className="mb-5 font-bold">{bannerTwo?.title}</h2>
                  <p className="mb-4">{bannerTwo?.description}</p>
                  <Button type="primary" onClick={handleScrollToTop}>
                    {bannerTwo?.cta}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}

        {/* <div className="energy-home__section">
        <Row gutter={[0, 16]}>
          <Col span={24} md={12} className="energy-home__col">
            <div className="energy-home__box h-full">
              <Row gutter={[16, 16]}>
                <Col span={24} md={8}>
                  <div
                    className="project-item"
                    onClick={handleNavigateToListPage}
                  >
                    <img src={Sample1} alt="sample" />
                    <div className="project-item__info">
                      <p className="title mb-2">Rue destienn...</p>
                      <Badge
                        count="G"
                        color={MAP_COLOR_DPE["G"]}
                        style={{ minWidth: "40px" }}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24} md={8}>
                  <div
                    className="project-item"
                    onClick={handleNavigateToListPage}
                  >
                    <img src={Sample2} alt="sample" />
                    <div className="project-item__info">
                      <p className="title mb-2">RRue du Bac</p>
                      <Badge
                        count="B"
                        color={MAP_COLOR_DPE["B"]}
                        style={{ minWidth: "40px" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24} md={12} className="energy-home__col">
            <div
              className="energy-home__box h-full"
              style={{
                borderLeft: 0,
              }}
            >
              <div className="border border-black rounded-[10px] px-1 py-2">
                <ul className="list flex flex-col gap-3 list-disc pl-5">
                  <li className="green">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </li>
                  <li className="green">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </li>
                  <li className="yellow">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}

        <Modal
          className="modal-result-autocomplete"
          open={isShowModalResultAutocomplete}
          centered
          title={`Résultats pour ${search}`}
          onCancel={() => setIsShowModalResultAutocomplete(false)}
          onOk={() => setIsShowModalResultAutocomplete(false)}
          cancelButtonProps={{ className: "hidden" }}
          okButtonProps={{ className: "hidden" }}
        >
          {!!resultAutocomplete.length ? (
            <div className="autocomplete-box">
              <div className="flex flex-wrap items-center justify-around gap-[30px]">
                {resultAutocomplete.slice(0, 3).map((item, index) => (
                  <div className="autocomplete-item" key={index}>
                    <div className="autocomplete-item__title">
                      <p
                        style={{
                          background: MAP_COLOR_DPE[_.get(item, "label.1")],
                        }}
                        className="min-w-[32px] rounded-full text-center"
                      >
                        {_.get(item, "label.1")}
                      </p>
                      <p>{_.get(item, "ademe_id.1")}</p>
                    </div>
                    <div className="autocomplete-item__content">
                      <ul
                        style={{ listStyleType: "disc" }}
                        className="flex flex-col gap-2 pl-8"
                      >
                        <li>
                          <p className="truncate">
                            {_.get(item, "date_dpe.0")}:{" "}
                            {_.get(item, "date_dpe.1")}
                          </p>
                        </li>
                        <li>
                          <p className="truncate">{_.get(item, "address.1")}</p>
                        </li>
                        <li>
                          <p className="truncate">
                            {_.get(item, "floor.0")}: {_.get(item, "floor.1")}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="flex items-center cursor-pointer justify-end mt-6 gap-3"
                      onClick={() => handleNavigateToHomeResult(item)}
                    >
                      <span>Selectionner</span>
                      <ArrowRightOutlined />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </Modal>
        {contextHolder}
      </div>
    </>
  );
}

export default Home;
